import { Suspense } from 'react';

import ComboProducts from 'Component/ComboProducts/ComboProducts';
import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader';
import ExpandableContent from 'SourceComponent/ExpandableContent';
import Link from 'SourceComponent/Link';
import { ProductReviewRating } from 'SourceComponent/Product/Product.component';
import ProductGallery from 'SourceComponent/ProductGallery';
import { REVIEW_POPUP_ID } from 'SourceComponent/ProductReviews/ProductReviews.config';
import { CmsBlock } from 'SourceRoute/Checkout/Checkout.component';
import NoMatchHandler from 'SourceRoute/NoMatchHandler';
import {
    Popup,
    ProductActions,
    ProductAttributes,
    ProductInformation,
    ProductLinks,
    ProductPageComponent as SourceProductPageComponent,
    ProductReviewForm,
    ProductReviews,
    ProductTabs,
} from 'SourceRoute/ProductPage/ProductPage.component';
import { ProductPageTabs } from 'SourceRoute/ProductPage/ProductPage.config';
import { ProductPageTab } from 'SourceRoute/ProductPage/ProductPage.type';
import { LinkedProductType } from 'SourceStore/LinkedProducts/LinkedProducts.type';
import { ReactElement } from 'SourceType/Common.type';
import { Props } from 'SourceUtil/DynamicReducer/DynamicReducer.type';

import arrow_right_blue from '../../style/icons/arrow-right-blue.svg';
import minus from '../../style/icons/minus.svg';
import plus from '../../style/icons/plus.svg';

import './ProductPage.override.style';

export {
    ProductReviews,
    ProductTabs,
    ProductAttributes,
    ProductReviewForm,
    ProductLinks,
    ProductInformation,
    Popup,
    ProductActions,
};
/* eslint-disable */

/** @namespace TrouperPwa/Route/ProductPage/Component */
export class ProductPageComponent extends SourceProductPageComponent {
    state = {
        activeAccordion: 'productTabs',
    };

tabMap: Record<string, ProductPageTab>;

constructor(props: Props) {
    // @ts-ignore
    super(props);

    this.state = {
        activeAccordion: 'productTabs',
    };

    this.tabMap = {
        [ProductPageTabs.INFORMATION]: {
            name: __("Description"),
            shouldTabRender: () => !this.props.isInformationTabEmpty,
            render: (key: string) => this.renderProductInformationTab(key),
        },
    };
}
componentDidUpdate(prevProps: Props) {
    const { tabListHeadings, dataSource } = this.props as any;

    if (prevProps.dataSource !== dataSource && dataSource) {
        const tabNames = tabListHeadings?.split(",").map((tab: string) => tab.trim()) || [];
        this.tabMap = this.createTabMap(tabNames, dataSource);
        this.forceUpdate();
    }
}

createTabMap(tabNames: string[], dataSource: any): Record<string, ProductPageTab> {
    const tabMap: Record<string, ProductPageTab> = {};

    tabMap[ProductPageTabs.INFORMATION] = {
        name: __('Description'),
        shouldTabRender: () => !this.props.isInformationTabEmpty,
        render: (key: string) => this.renderProductInformationTab(key),
    };

    tabNames.slice(1).forEach((tabName, index) => {
        const attributeValue = dataSource?.attributes?.[tabName]?.attribute_value;

        if (attributeValue?.trim()) {
            const tabKey = `dynamic_tab_${index + 1}`;
            tabMap[tabKey] = {
                name: __(tabName
                    .toLowerCase()
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')),
                shouldTabRender: () => true,
                render: (key: string) => this.renderDynamicTabContent(key, tabName, attributeValue),
            };
        }
    });

    return tabMap;
}

renderDynamicTabContent(key: string, tabName: string, attributeValue: string): ReactElement {
    return (
        <Suspense fallback={<Loader />} key={key}>
            <ExpandableContent mix={{ block: 'ProductReviews', elem: 'Content' }} heading={tabName
                    .toLowerCase()
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')}>
                <div dangerouslySetInnerHTML={{ __html: attributeValue }} />
            </ExpandableContent>
        </Suspense>
    );
}


    toggleAccordion = (section: string) => {
        this.setState((prevState) => ({
            // @ts-ignore
            activeAccordion: prevState.activeAccordion === section ? null : section,
        }));
    };

    renderProductActions() {
        const {
            getLink,
            dataSource,
            areDetailsLoaded,
            setActiveProduct,
            parameters,
            hideReviews,
            pathname,
        } = this.props as any;

        return (
                <div block="ProductPage" elem="ProductActions">
                    <Suspense fallback={ this.renderProductActionsPlaceholder() }>
                        <ProductActions
                          getLink={ getLink }
                          product={ dataSource }
                          parameters={ parameters }
                          areDetailsLoaded={ areDetailsLoaded }
                          setActiveProduct={ setActiveProduct }
                          hideReviews = { hideReviews }
                          pathname = { pathname }
                        />
                    </Suspense>
                </div>
        );
    }


    renderProductDesktopMainData(): ReactElement {
        return (
            <>
                { this.renderProductName() }
            </>
        );
    }

    scrollToTarget = () => {
        const targetElement = document.getElementById('scrollToReviewSection');
        if (targetElement) {
          const topPosition = targetElement.getBoundingClientRect().top;
          window.scrollTo({
            top: topPosition - 150,
            // behavior: 'smooth',
          });
        }
      };

       renderRatingSummary(): ReactElement {
            const {
                dataSource: {
                    review_summary: {
                         rating_summary = null,
                            review_count = 0,
                    } = {},
                },
                isEnabled,
            } = this.props as any;

            if (!isEnabled) {
                return null;
            }
    
            return (
                <div 
                block="ProductPage" elem="ProductTopReviewSection"
                >
<div style={{borderRight:"1px solid #B8B7B5",paddingRight:"12px"}}>
                <Suspense fallback={ null }>
                    <ProductReviewRating
                      summary={ rating_summary || 0 }
                      count={ review_count }
                    />
                </Suspense>
                </div>
                <div
                style={{borderRight:"1px solid #B8B7B5",paddingRight:"12px"}}
                block="ProductPage" elem="ProductTopReviewCount"
                >
                    {`${review_count} Reviews`}
                </div>
                <div
                block="ProductPage" elem="ProductTopReviewCount"
                style={{cursor:"pointer"}}
                onClick={() => this.scrollToTarget()}
                >Add a Review</div>
                </div>
            );
        }

    renderProductName(): ReactElement {
        const { dataSource: { name }, dataSource } = this.props;

        const brand = dataSource?.attributes?.brand;
    
        const brandLabel = brand?.attribute_options[brand?.attribute_value]?.label;

        return (
            <>
                <h1 block="ProductPage" elem="Title" itemProp="name">
                    { name }
                </h1>
                {brandLabel && 
                    <div 
                    block="ProductPage" elem="ProductTopBy"
                    >By: <Link to='/brands'> <span>{ brandLabel }</span> </Link> </div>
                }
                    { this.renderRatingSummary() }
                    </>
        );
    }

    renderProductActionsHeader() {
        const {
            isMobile,
        } = this.props;

        return (
                <div block="ProductPage" elem="ProductActions">
                 {/* { !isMobile && this.renderProductDesktopMainData() } */}
                 { this.renderProductDesktopMainData() }
                </div>
        );
    }

    renderProductTabs(): ReactElement {
        const tabs = this.shouldTabsRender();
        const {
            dataSource,
        } = this.props;
        const videoUrl = dataSource?.attributes?.video_url_mp4_upload?.attribute_value;
        if (!tabs) {
            return null;
        }

        return (
                <Suspense fallback={ <div /> }>
                    <ContentWrapper>
                    {videoUrl ?  
                     <div block="ProductPage" elem="TabsMain">

                     <ProductTabs tabs={ tabs } />
                     <div block="ProductPage" elem="videoSec">
             <iframe
                 width="580"
                 height="392"
                 src={videoUrl}
                 title="YouTube video player"
                 frameBorder="0"
                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                 referrerPolicy="strict-origin-when-cross-origin"
                 allowFullScreen
             ></iframe>
         </div>
                     </div> : <ProductTabs tabs={ tabs } />
                    }
                   
                    </ContentWrapper>
                </Suspense>
        );
    }


      renderProductAttributesTab(key: string): ReactElement {
            const {
                activeProduct,
                areDetailsLoaded,
            } = this.props;
    
            return (
                <Suspense fallback={ <Loader /> } key={ key }>
                    <ProductAttributes
                      product={ activeProduct }
                      areDetailsLoaded={ areDetailsLoaded }
                      key={ key }
                    />

                </Suspense>
            );
        }

       renderProductReviewsTab(key: string): ReactElement {
            const {
                dataSource,
                areDetailsLoaded,
            } = this.props;
    
            return (
                <Suspense fallback={ <Loader /> } key={ key }>
                    <ProductReviews
                      product={ dataSource }
                      areDetailsLoaded={ areDetailsLoaded }
                      key={ key }
                    />
                </Suspense>
            );
        }

        renderComboProducts(): ReactElement {
            const {
                dataSource,
            } = this.props as any;

            return (
                <Suspense fallback={ <Loader /> }>
                    <ComboProducts dataSource = {dataSource} />
                </Suspense>
            );
        }

        renderProductInformationTab(key: string): ReactElement {
            const {
                dataSource: { description: { html } = {} },
                areDetailsLoaded,
            } = this.props;
    
            if (!html) {
                return null;
            }
    
            return (
                <Suspense fallback={ null }>
                    <ProductInformation
                      htmlDescription={ html }
                      areDetailsLoaded={ areDetailsLoaded }
                      key={ key }
                    />
                </Suspense>
            );
        }

        renderNutrintionalInfoTab(key: string): ReactElement {
            const {
                dataSource,
            } = this.props;
    
            return (
                <Suspense fallback={ <Loader /> } key={ key }>
                    <ExpandableContent
                                      mix={ { block: 'ProductReviews', elem: 'Content' } }
                                      heading={ "Nutritional information" }
                                    >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: dataSource?.attributes?.nutritional_info?.attribute_value || '',
                        }}
                    />
                    </ExpandableContent>
                </Suspense>
            );
        }

    renderAdditionalSections(): ReactElement {
        const { activeAccordion } = this.state;

        const {
            areDetailsLoaded,tabListHeadings, dataSource
        } = this.props as any;

        if (!areDetailsLoaded || !tabListHeadings) {
            return <Loader />;
        }

        return (
            <>
            {dataSource?.description.html && 
            <div>
            <ContentWrapper>

            <div block="ProductTabs" elem="Heading"
           
            >
                <h3>About this product</h3>
                <div 
              onClick={ () => this.toggleAccordion('productTabs') }
                >
{ ' ' }
{ activeAccordion === 'productTabs' ? <img style={{height:"32px", width:"32px"}} src={minus} alt="minus" /> : <img style={{height:"32px", width:"32px"}} src={plus} alt="plus" /> }
{ ' ' }
                </div>
            </div>
            </ContentWrapper>
            { activeAccordion === 'productTabs' && (
                <div>

                    {this.renderProductTabs()}
                </div>
            ) }
         </div>
            }
{ ' ' }
{ /* { this.renderProductTabs() } */ }
                    <Suspense fallback={ null }>
                        <ProductLinks
                          linkType={ LinkedProductType.UPSELL }
                          title={ __('You may also like') }
                          areDetailsLoaded={ areDetailsLoaded }
                        />
                    </Suspense>
                    <ContentWrapper>
                        <CmsBlock identifier="pdp-page-static-banner" />
                    </ContentWrapper>
                    <Suspense fallback={ null }>
                        <ProductLinks
                          linkType={ LinkedProductType.RELATED }
                          title={ __('Related products') }
                          areDetailsLoaded={ areDetailsLoaded }
                        />
                    </Suspense>

                        <div block='RecommendedSection'>
                            <CmsBlock identifier="usp-section" />
                        </div>
                        
                        <div>

                        {this.renderProductReviewsTab("key")}
                        </div>
            </>
        );
    }

    renderProductPageContent(): ReactElement {
        const {
            areDetailsLoaded,
            activeProduct,
            useEmptyGallerySwitcher,
            isVariant,
            hideReviews,
            pathname
        } = this.props as any;

        return (
            <>
            <span>

                    <ProductGallery
                      product={ activeProduct }
                      areDetailsLoaded={ areDetailsLoaded }
                      isWithEmptySwitcher={ useEmptyGallerySwitcher }
                      showLoader={ isVariant }
                      // @ts-ignore
                      hideReviews = { hideReviews }
                      pathname = { pathname }
                    />
                    { hideReviews
                    && (
<div block="ProductPage" elem="PdpPathLink"
>
  
        <Link to={ pathname }>View More Details</Link>
        <img src={
        arrow_right_blue
    } alt="arrow_right_blue" />
</div>
                    ) }
            </span>
                    { this.renderProductActions() }
            </>
        );
    }


        renderReviewPopup(): ReactElement {
            const { dataSource, isMobile } = this.props;

            return (
                <Suspense fallback={ null }>
                    <Popup
                      id={ REVIEW_POPUP_ID }
                      mix={ { block: 'ProductReviews', elem: 'Popup' } }
                    >
                        <Suspense fallback={ null }>
                            <ProductReviewForm product={ dataSource } />
                        </Suspense>
                    </Popup>
                </Suspense>
            );
        }

    render(): ReactElement {
        const {
            hideReviews,
        } = this.props as any;
        return (
                <NoMatchHandler>
                    <main
                      block="ProductPage"
                      aria-label="Product page"
                      itemScope
                      itemType="http://schema.org/Product"
                    >
                        <div 
                      id='showFloatAddToCart'
                      >

                         <ContentWrapper>

                            {!hideReviews && this.renderProductActionsHeader() }
                         </ContentWrapper>

                        <ContentWrapper
                          wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                          label={ __('Main product details') }
                        >
                            { this.renderProductPageContent() }
                        </ContentWrapper>
                        </div>
                        {!hideReviews && (
    <>
    <div>

        { this.renderComboProducts() }
        {this.renderAdditionalSections()}
        {this.renderReviewPopup()}
    </div>
    </>
)}
                        {/* { this.renderAdditionalSections() }
                        { this.renderReviewPopup() } */}
                    </main>
                </NoMatchHandler>
        );
    }
}

export default ProductPageComponent;
