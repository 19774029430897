import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import close_gray from '../../style/icons/close-icon-grey.svg';

import './CustomPopup.scss';

interface CustomPopupProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
    containerWidth?:any;
    popClass?:any;
}

/** @namespace TrouperPwa/Component/CustomPopup/CustomPopup */
export const CustomPopup: React.FC<CustomPopupProps> = ({
    isOpen, onClose, children, containerWidth, popClass,
}) => {
    const popupRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    if (!isOpen) {
        return null;
    }

    return ReactDOM.createPortal(
        <div block="CustomPopup">
            <div
              block="CustomPopup"
              elem="Wrapper"
              ref={ popupRef }
              style={ { width: containerWidth } }
              mods={ { popClass } }
            >
                <button block="CustomPopup" elem="Close" onClick={ onClose }>
                    <img src={ close_gray } alt="close-gray-icon " />
                </button>
                { children }
            </div>
        </div>,
        document.body,
    );
};

// Adding defaultProps for containerWidth
CustomPopup.defaultProps = {
    containerWidth: '100%', // Default width when not provided,
    popClass: '',
};

export default CustomPopup;
