import { Field, Mutation, Query } from '@tilework/opus';
/**
 * @namespace TrouperPwa/Query/ComboProducts/Query */
export class ComboProductsQuery {
    getComboProductQuery(parent_prod_sku: string): Query<'comboProductQuery', { bundle_pack_name: string;
        bundle_pack_id:number;
        prod_id: string; parent_prod_name: string; parent_prod_sku: string; parent_prod_image: string;
        parent_prod_price: number; parent_prod_discount_amount: number; parent_prod_special_price: number;
        final_price: number; final_discount: number; items_count: number; combo_products: any[]; }> {
        /* eslint-disable */
            // @ts-ignore
        return new Query<'comboProductQuery', { bundle_pack_name: string;bundle_pack_id:number;
            prod_id: string; parent_prod_name: string; parent_prod_sku: string; parent_prod_image: string;
            parent_prod_price: number;
            parent_prod_discount_amount: number; parent_prod_special_price: number;
            final_price: number; final_discount: number;
            items_count: number;combo_actual_final_price:number; combo_products: any[]; }>('comboProductQuery')
            .addArgument('sku', 'String!', parent_prod_sku)
            .addField('bundle_pack_name')
            .addField('bundle_pack_id')
            .addField('prod_id')
            .addField('parent_prod_name')
            .addField('parent_prod_sku')
            .addField('parent_prod_image')
            .addField('parent_prod_price')
            .addField('parent_prod_discount_amount')
            .addField('parent_prod_display_price')
            .addField('parent_prod_special_price')
            .addField('bundle_pack_final_price')
            .addField('bundle_pack_final_savings')
            .addField('bundle_pack_total_final_price')
            .addField('items_count')
            .addField(
                new Field('combo_products').addFieldList(this._getComboProductFields()),
            );
    }

    getComboProductSelectedQuery(mainProductId: number, comboIds: number[]): Query<'comboProductSelectedQuery', {
        prod_id: string;
        parent_prod_sku: string;
        bundle_pack_final_price: number;
        bundle_pack_final_savings: number;
        bundle_pack_total_final_price: number;
        items_count: number;
        combo_products: any[];
    }> {
        return new Query<'comboProductSelectedQuery', {
            prod_id: string;
            parent_prod_sku: string;
            bundle_pack_final_price: number;
            bundle_pack_final_savings: number;
            bundle_pack_total_final_price: number;
            items_count: number;
            combo_products: any[];
        }>('comboProductSelectedQuery')
            .addArgument('main_product_id', 'Int!', mainProductId)
            .addArgument('combo_ids', '[Int]!', comboIds)
            .addField('prod_id')
            .addField('parent_prod_sku')
            .addField('bundle_pack_final_price')
            .addField('bundle_pack_final_savings')
            .addField('bundle_pack_total_final_price')
            .addField('items_count')
            .addField(
                new Field('combo_products').addFieldList(this._getComboProductFields()),
            );
    }

    addComboProductsToCart(
        cartId: string,
        products: { sku: string; quantity: number }[],
        combo_pack_id: number,
    ): Mutation<'addComboProductsToCart', {
            success: boolean;
            message: string;
            cart_items: { sku: string; quantity: number; name: string; price: number }[];
        }> {
        /* eslint-disable */
            // @ts-ignore
        return new Mutation<'addComboProductsToCart', {
            success: boolean;
            message: string;
            cart_items: { sku: string; quantity: number; name: string; price: number }[];
        }>('addComboProductsToCart')
            .addArgument('cart_id', 'String!', cartId)
            .addArgument('products','[AddComboProductsInput]!', products)
            .addArgument('combo_pack_id','Int!', combo_pack_id)
            .addField('success')
            .addField('message')
            .addField(
                new Field('cart_items')
                    .addField('sku')
                    .addField('quantity')
                    .addField('name')
                    .addField('price'),
            );
    }

    _getComboProductFields(): any[] {
        return [
            new Field<'combo_prod_id', string>('combo_prod_id'),
            new Field<'combo_prod_name', string>('combo_prod_name'),
            new Field<'combo_prod_sku', string>('combo_prod_sku'),
            new Field<'combo_prod_image', string>('combo_prod_image'),
            new Field<'actual_price', number>('actual_price'),
            new Field<'qty', number>('qty'),
            new Field<'combo_discount_amount', number>('combo_discount_amount'),
            new Field<'combo_special_price', number>('combo_special_price'),
            new Field<'combo_prod_url', string>('combo_prod_url'),
            new Field<'combo_prod_display_price', number>('combo_prod_display_price'),
        ];
    }
}

export default ComboProductsQuery;
