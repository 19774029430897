import { Field } from 'Util/Query';

/**
 * @namespace TrouperPwa/Query/BlogListingPosts/Query */

export class BlogListingPostsQuery {
    blogPosts(currentPage: number, pageSize: number, sort: string[], filter: any) {
        return new Field('blogPosts')
            .addArgument('currentPage', 'Int', currentPage)
            .addArgument('pageSize', 'Int', pageSize)
            .addArgument('sort', '[String]', sort)
            .addArgument('filter', 'BlogPostsFilterInput', filter)
            .addFieldList([
                'total_count',
                'total_pages',
                new Field('items').addFieldList([
                    'post_id',
                    'title',
                    'featured_image',
                    'featured_img_alt',
                    'featured_list_image',
                    'featured_list_img_alt',
                    'short_filtered_content',
                    'post_url',
                    'creation_time',
                    new Field('categories').addFieldList([
                        'category_id',
                        'title',
                        'category_url',
                    ]),
                    new Field('hotspot').addFieldList([
                        'id',
                        'name',
                        'top',
                        'left',
                        'price',
                        'url',
                        'special_price',
                    ]),
                ]),
            ]);
    }
}

export default BlogListingPostsQuery;
