import { ReactElement } from 'react';

import {
    FieldGroupComponent as SourceFieldGroupComponent,
} from 'SourceComponent/FieldGroup/FieldGroup.component';

import './FieldGroup.style';
/* eslint-disable */
/** @namespace TrouperPwa/Component/FieldGroup/Component */
export class FieldGroupComponent extends SourceFieldGroupComponent {

         render(): ReactElement {
             const {
                 validationResponse,
                 children,
                 setRef,
                 attr,
                 events,
                 mods,
             } = this.props as any;

             return (
                 <div block="FieldGroup" elem="Wrapper" mods={ mods }>
                     { this.renderLabel() }
                     <div
                       // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                       { ...attr }
                       // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction, @typescript-eslint/no-explicit-any
                       { ...events as any }
                       ref={ (elem) => setRef(elem) }
                       block="FieldGroup"
                       mods={ {
                           isValid: validationResponse === true,
                           hasError: validationResponse !== true && Object.keys(validationResponse || {}).length !== 0,
                       } }
                     >
                         { children }
                     </div>
                     { this.renderErrorMessages() }
                     { this.renderSubLabel() }
                 </div>
             );
         }
 
}

export default FieldGroupComponent;
