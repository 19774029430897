/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
/* eslint-disable */

import { createRef, PureComponent, ReactElement } from 'react';
import AddIcon from 'Component/AddIcon';
import ChevronIcon from 'Component/ChevronIcon';
import { Directions } from 'Component/ChevronIcon/ChevronIcon.config';
import MinusIcon from 'Component/MinusIcon';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import { isCrawler, isSSR } from 'Util/Browser';
import { getFixedElementHeight } from 'Util/CSS';
import Plus_icon from '../../style/icons/plus.svg';
import Minus_icon from '../../style/icons/minus.svg';
import search_gray_icon from '../../style/icons/search_gray_icon.svg';


import { ExpandableContentComponentProps, 
    // ExpandableContentComponentState 
} from './ExpandableContent.type';

import './ExpandableContent.style';

interface ExpandableContentComponentState {
    isContentExpanded: boolean;
    prevIsContentExpanded: boolean;
    searchTerm?: any;
}

/** @namespace TrouperPwa/Component/ExpandableContent/Component */
export class ExpandableContentComponent extends PureComponent<ExpandableContentComponentProps, ExpandableContentComponentState> {
    static defaultProps: Partial<ExpandableContentComponentProps> = {
        heading: '',
        isContentExpanded: false,
        onClick: undefined,
        children: [],
        isArrow: false,
        mods: {},
    };

    state : ExpandableContentComponentState = {
        searchTerm: '',
        isContentExpanded: false,
        prevIsContentExpanded: false
      };
    

    expandableContentRef = createRef<HTMLElement>();

    __construct(props: ExpandableContentComponentProps): void {
        super.__construct?.(props);
        const { isContentExpanded } = this.props;

        const isForceExpanded = isSSR() || isCrawler();

        this.toggleExpand = this.toggleExpand.bind(this);

        this.state = {
            isContentExpanded: isForceExpanded || isContentExpanded,
            // eslint-disable-next-line react/no-unused-state
            prevIsContentExpanded: isContentExpanded,
        };
    }

    static getDerivedStateFromProps(
        { isContentExpanded }: Pick<ExpandableContentComponentProps, 'isContentExpanded'>,
        { prevIsContentExpanded }: Pick<ExpandableContentComponentState, 'prevIsContentExpanded'>,
    ): ExpandableContentComponentState | null {
        if (isContentExpanded !== prevIsContentExpanded) {
            return {
                prevIsContentExpanded: isContentExpanded,
                isContentExpanded,
            };
        }

        return null;
    }

    scrollToExpandedContent(): void {
        const { isContentExpanded } = this.state;
        const elem = this.expandableContentRef && this.expandableContentRef.current;

        if ((isContentExpanded && !elem) || !elem) {
            return;
        }

        const elemToWindowTopDist: number = elem.getBoundingClientRect().top;
        const windowToPageTopDist: number = document.body.getBoundingClientRect().top;
        const topToElemDistance: number = elemToWindowTopDist - windowToPageTopDist;
        const {
            total: totalFixedElementHeight,
            bottom: bottomFixedElementHeight,
        } = getFixedElementHeight();

        const elemMaxOffsetHeight = screen.height > elem.offsetHeight + bottomFixedElementHeight
            ? elem.offsetHeight
            : screen.height - totalFixedElementHeight;
        const scrollTo = topToElemDistance - (screen.height - bottomFixedElementHeight - elemMaxOffsetHeight);

        if (-windowToPageTopDist >= scrollTo) {
            return;
        }

        window.scrollTo({ behavior: 'smooth', top: scrollTo });
    }

    toggleExpand(): void {
        const { onClick } = this.props;

        if (onClick) {
            onClick();

            return;
        }
        this.setState(
            ({ isContentExpanded }) => ({ isContentExpanded: !isContentExpanded }),
            () => this.scrollToExpandedContent(),
        );
    }

    renderButton(): ReactElement {
        const { isContentExpanded } = this.state;
        const { heading, mix, children, device: { isMobile } } = this.props;
   const isReactElementWithProps = (element: any): element is React.ReactElement =>
    element && typeof element === 'object' && 'props' in element;
   let stock_status = 
    children && typeof children === "object" && "props" in children
        ? children.props?.children?.props?.children?.[0]?.props?.attribute?.attribute_code
        : undefined;

let isBoolean = false;

if (Array.isArray(children)) {
    const firstChild = children[0];
    if (isReactElementWithProps(firstChild)) {
        isBoolean = firstChild.props?.children?.props?.children?.[0]?.props?.attribute?.is_boolean;
    }
} else if (isReactElementWithProps(children)) {
    isBoolean = children.props?.children?.props?.children?.[0]?.props?.attribute?.is_boolean;
}
if (isMobile) {
    return (
        <div
            role="button"
            tabIndex={ 0 }
            block="ExpandableContent"
            elem="Button"
            mods={ { isContentExpanded } }
            mix={ { ...mix, elem: 'ExpandableContentButton' } }
            onClick={ this.toggleExpand }
            onKeyDown={ this.toggleExpand }
          >
              <div
                block="ExpandableContent"
                elem="Heading"
                mods={ { isContentExpanded } }
                mix={ { ...mix, elem: 'ExpandableContentHeading' } }
              >
                  { typeof heading === 'string' ? (
                      <TextPlaceholder content={ heading } length={ TextPlaceHolderLength.MEDIUM } />
                  ) : (
                      heading
                  ) }
              </div>
              { this.renderButtonIcon() }
          </div>
    );
}

        return (
            <>
            {/* {heading !== "Hide out of stock product" && isBoolean ? null : <div */}
            {stock_status !== "custom_stock_status" && isBoolean ? null : <div
                role="button"
                tabIndex={ 0 }
                block="ExpandableContent"
                elem="Button"
                mods={ { isContentExpanded } }
                mix={ { ...mix, elem: 'ExpandableContentButton' } }
                onClick={ this.toggleExpand }
                onKeyDown={ this.toggleExpand }
                style={{borderTop: heading === "Brand" ? "1px solid #B8B7B5" : "", marginTop: heading === "Brand" ? "32px" : ""}}
              >
                  <div
                    block="ExpandableContent"
                    elem="Heading"
                    mix={ { ...mix, elem: 'ExpandableContentHeading' } }
                  >
                    {heading === "Brand" ? <div block="ExpandableContent"
                    elem="Heading" style={{margin:"0px 0 24px 0"}}>Filter by</div> : null }
                      { typeof heading === 'string' ? (
                          <TextPlaceholder content={ stock_status === "custom_stock_status" ? "Availability" : heading } length={ TextPlaceHolderLength.MEDIUM } />
                      ) : (
                          heading
                      ) }
                  </div>
                  { this.renderButtonIcon() }
              </div>}
              </>
        );
    }

    renderButtonIcon(): ReactElement | null {
        const { isContentExpanded } = this.state;
        const { isArrow, device: { isMobile } } = this.props;

        if (!isMobile) {
            return null;
        }

        if (isArrow) {
            return <ChevronIcon direction={ isContentExpanded ? Directions.TOP : Directions.BOTTOM } />;
        }

        return this.renderTogglePlusMinus();
    }

    renderTogglePlusMinus(): ReactElement {
        const { isContentExpanded, searchTerm } = this.state;

        if (isContentExpanded) {
            return <img style={{width:"24px",height:"24px"}} src={Minus_icon} alt="Minus_icon" />;
        }

        return <img style={{width:"24px",height:"24px"}} src={Plus_icon} alt="Plus_icon" />;
    }

    handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchTerm: event.target.value });
      };

      renderContent(): ReactElement {
        const { children, mix, heading } = this.props;
        const { isContentExpanded, searchTerm } = this.state;
        const mods = { isContentExpanded };
    
        const childrenArray = React.Children.toArray(children);
    
        if (!Array.isArray(childrenArray)) {
            console.error("Children are not in an array format");
        }
    
        const updatedChildren = React.Children.toArray(children).map((child: any) => {
            const childArray = React.Children.toArray(child.props?.children?.props?.children);
        
            if (!childArray.length) {
                return child; 
            }
            const modifiedChildren = childArray.map((nestedChild: any) => {
                const attributeOptions = nestedChild?.props?.attribute?.attribute_options;
        
                if (!attributeOptions) {
                    return nestedChild; 
                }
        
                const filteredOptions = Object.values(attributeOptions).filter((option: any) =>
                    option.label?.toLowerCase().includes(searchTerm?.toLowerCase())
                );
        
                if (filteredOptions.length === 0) {
                    return null;
                }

        
                const updatedOptions: any = {};
                filteredOptions.forEach((option: any) => {
                    if (!updatedOptions[option.value_string]) {
                        updatedOptions[option.value_string] = option; 
                    }
                });

                return React.cloneElement(nestedChild, {
                    ...nestedChild.props,
                    attribute: {
                        ...nestedChild.props.attribute,
                        attribute_options: updatedOptions,
                        attribute_values: Object.keys(updatedOptions) || [],
                    },
                });
            }).filter(Boolean);

            return React.cloneElement(child, {
                ...child.props,
                children: React.cloneElement(child.props.children, {
                    ...child.props.children.props,
                    children: modifiedChildren
                    ?.filter((val) => {
                        const firstChildOptions = modifiedChildren?.[0]?.props?.attribute?.attribute_values;
                        if (!firstChildOptions) {
                            return false;
                        }
                        return val?.key?.slice(2) && firstChildOptions?.includes(val?.key?.slice(2));
                    }) || [],
                }),
            });
        });
        
        
        const contentToRender = searchTerm && updatedChildren.length > 0 ? updatedChildren : childrenArray;

        return (
            <div
                block="ExpandableContent"
                elem="Content"
                mods={mods}
                mix={{ ...mix, elem: 'ExpandableContentContent', mods }}
            >
                <div block="ExpandableContent" elem="Expand">
                    {heading === "Brand" && React.isValidElement(children) &&
    React.isValidElement(children.props?.children) &&
    Array.isArray(children.props.children.props?.children) &&
    children.props.children.props.children.length  > 5 && (
                        <input
                            style={{ marginBottom: "16px" }}
                            type="text"
                            placeholder="Find a brand"
                            value={searchTerm}
                            onChange={this.handleSearchChange}
                        />
                        
                    )}
                    {heading === "Brand" && React.isValidElement(children) &&
    React.isValidElement(children.props?.children) &&
    Array.isArray(children.props.children.props?.children) &&
    children.props.children.props.children.length  > 5 && (

                        <img block="ExpandableContent" elem="SearchBrandIcon" src={search_gray_icon} alt="search_gray_icon" />
                    )}
                    {heading === "Brand" ? contentToRender : childrenArray}
                    {heading === "Brand" && contentToRender?.[0]?.props?.children?.props?.children?.length==0 && 
                    <div style={{ textAlign: "center" }} key="no-data-found">
                    No data found
                </div>}
                </div>
            </div>
        );
        
    }
    

    render(): ReactElement {
        const { mix, mods } = this.props;

        return (
            <article
              block="ExpandableContent"
              mix={ mix }
              mods={ mods }
              ref={ this.expandableContentRef }
            >
                { this.renderButton() }
                { this.renderContent() }
            </article>
        );
    }
}
export default ExpandableContentComponent;
