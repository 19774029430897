import { format } from 'date-fns';
import React, { Suspense, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import ContentWrapper from 'Component/ContentWrapper';
import { Pagination } from 'Component/ProductList/ProductList.component';
import BlogListingPostsQuery from 'Query/BlogListingPosts.query';
import LoaderComponent from 'SourceComponent/Loader';
import { fetchQuery } from 'SourceUtil/Request/Query';
import BreadcrumbsDispatcher from 'Store/Breadcrumbs/Breadcrumbs.dispatcher';

import close_reset_icon from '../../style/icons/close-reset-icon.svg';
import { BlogPostItem, BlogPostsData, BlogPostsVars } from './BlogPosts';

import './BlogListingPosts.scss';

interface BlogListingPostsProps {
    updateBreadcrumbs: (breadcrumbs: any[]) => void;
}

/** @namespace TrouperPwa/Component/BlogListingPosts/BlogListingPosts */
export const BlogListingPosts: React.FC<BlogListingPostsProps> = ({ updateBreadcrumbs }) => {
    const myData: any = useSelector((state: any) => state?.ConfigReducer?.grid_per_page);

    const location = useLocation();
    const PAGE_SIZE = myData;
    const [posts, setPosts] = useState<BlogPostItem[]>([]);
    const [totalPages, setTotalPages] = useState<any>('');
    const [filter, setFilter] = useState<any>({});
    const [filterText, setFilterText] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const searchParams = new URLSearchParams(location.search);
    const page = Number(searchParams.get('page')) || 1;

    const handleCategoryFilter = (categoryId: string, category_title: string) => {
        setFilter({
            category_id: { eq: categoryId },
        });
        setFilterText(category_title);
    };

    useEffect(() => {
        const categoryParam = searchParams.get('category');
        const categoryIdParam = searchParams.get('categoryId');
        const timeSec = 100;

        if (categoryParam && categoryIdParam) {
            setFilterText(categoryParam);

            setTimeout(() => {
                setFilter({
                    category_id: { eq: categoryIdParam },
                });
            }, timeSec);
            window.history.replaceState(null, '', '/blog');
        } else {
            setFilter({});
            setFilterText(null);
        }
    }, [location.search]);

    useEffect(() => {
        const fetchBlogPosts = async () => {
            setLoading(true);
            setError(null);

            const variables: BlogPostsVars = {
                currentPage: page,
                pageSize: PAGE_SIZE,
                sort: ['ASC'],
                filter,
            };

            const query = new BlogListingPostsQuery().blogPosts(
                variables.currentPage,
                variables.pageSize,
                variables.sort,
                variables.filter,
            );
            /* eslint-disable */
            try {
                // @ts-ignore
                const response: any = await fetchQuery<BlogPostsData, BlogPostsVars>(query);
                if (response?.blogPosts?.items) {
                  setPosts(response.blogPosts.items);
                  setTotalPages(response?.blogPosts?.total_pages);
                  updateBreadcrumbs([
                    { name: 'Blogs', url: '/blog' },
                ]);
                } else {
                    setPosts([]);
                }
            } catch (err: unknown) {
                setError(err instanceof Error ? err.message : 'Failed to fetch blog posts');
            } finally {
                setLoading(false);
            }
            /* eslint-disable */
        };

        fetchBlogPosts();
    }, [page, filter, filterText, updateBreadcrumbs]);
    if(loading){
        return <div style={{minHeight:"500px"}}>
            <LoaderComponent isLoading={ loading } />
        </div>
    }

    return (
      <ContentWrapper>
        <div block="BlogListingPosts">
            <div
            block="BlogListingPosts"
            elem="Blogheading"
            >Blogs</div>
            {/* { loading && <LoaderComponent isLoading={ loading } /> } */}
            { error && <p block="error">{ error }</p> }
            { !loading && !error && posts?.length === 0 && <p>No blog posts found!</p> }
            { !loading && !error && posts?.length > 0 && (
                <>
                <div 
                block="BlogListingPosts"
            elem="BlogRecentBlog"
                > 
                    <div block="BlogListingPosts" elem="BlogBannerImage" > <img src={posts?.[0].featured_image} alt={posts?.[0].featured_img_alt} /> 
                    {posts?.[0]?.hotspot?.map((spot: any) => (
                <div
                    key={spot.id}
                    block="BlogListingPosts"
                    elem="HotSpotMarker"
                    style={{
                        position: "absolute",
                        top: spot.top,
                        left: spot.left,
                        transform: "translate(-50%, -50%)",
                        cursor: "pointer",
                    }}
                >
                    <a href={spot.url} block="BlogListingPosts"
                    elem="HotSpotProduct">
                      <span block="BlogListingPosts"
                    elem="HotSpotProductName">{ spot?.name }</span>
                    <span block="BlogListingPosts"
                    elem={spot?.special_price ? "HotSpotProductSpecial" : "HotSpotProductPrice"} >{spot?.special_price ? spot?.special_price : spot?.price }</span>
                    </a>
                </div>
            ))}
                    </div>
                    <div>
                        <h3 block='BlogListingPosts' elem='blogTitle'>{posts?.[0].title}</h3>
                        <div block='BlogListingPosts' elem='blogContent' dangerouslySetInnerHTML={ {
                      __html: posts?.[0]?.short_filtered_content,
                  } }></div>
                  <div block='BlogListingPosts' elem='blogReadMoreButton'> <a href={`${posts?.[0]?.post_url}`}>Read More</a> </div>
                    </div>
                     </div>

                     <div block='BlogListingPosts' elem='blogCategoryFilterSectionMain'>
                        {filterText &&
                        <>
                        <p>Showing results for</p>
                        <div block='BlogListingPosts' elem='blogCategoryFilterSection'>
                            <span>
                        {filterText}
                            </span>
                        <div onClick={() => {setFilterText(null), setFilter({})}}>
                            <img src={close_reset_icon} alt="close-reset-icon" />
                        </div>
                        </div>
                        </>
                        }
                     </div>
                <div block='BlogListingPosts' elem='PostRow'>
                    { posts?.map((post: any) => (
                        <div block='BlogListingPosts' elem='PostCol' key={ post?.post_id }>
                          <div style={{position:"relative"}}>
                            <a href={`${post?.post_url}`} block='BlogListingPosts' elem='blogImg'><img src={ post?.featured_list_image }  alt="" /></a>
                            {post?.hotspot?.map((spot: any) => (
                <div
                    key={spot.id}
                    block="BlogListingPosts"
                    elem="HotSpotMarker"
                    style={{
                        position: "absolute",
                        top: spot.top,
                        left: spot.left,
                        transform: "translate(-50%, -50%)",
                        cursor: "pointer",
                    }}
                >
                    <a href={spot.url} block="BlogListingPosts"
                    elem="HotSpotProduct">
                      <span block="BlogListingPosts"
                    elem="HotSpotProductName">{ spot?.name }</span>
                    <span block="BlogListingPosts"
                    elem={spot?.special_price ? "HotSpotProductSpecial" : "HotSpotProductPrice"} >{spot?.special_price ? spot?.special_price : spot?.price }</span>
                    </a>
                </div>
            ))}
                            <div block='BlogListingPosts' elem='BlogCategories'>
                            {post?.categories?.map((category:any) => (
                <button
                    key={category.category_id}
                    block='BlogListingPosts' elem='BlogCategory'
                    // onClick={() => window.location.href = `${category.category_url}/${post?.post_id}`}
                    onClick={() => handleCategoryFilter(category.category_id, category.title)}
                >
                    {category.title}
                </button>
            ))}
                            </div>
                          </div>
                           <a href={`${post?.post_url}`}> <h3 block='BlogListingPosts' elem='blogTitle'>{ post?.title }</h3></a>
                            <p block='BlogListingPosts' elem='blogTime'>{post?.creation_time
    ? format(new Date(post?.creation_time), "MMMM do yyyy")
    : ""}
</p>
                            <div block='BlogListingPosts' elem='blogContent' dangerouslySetInnerHTML={ {
                      __html: post?.short_filtered_content,
                  } }></div>
                        <div block='BlogListingPosts' elem='blogReadMore'>
                            <a href={`${post?.post_url}`}>Read more</a>
                        </div>
                        </div>
                    )) }    
                </div>
                </>
            ) }
        </div>
{totalPages > 1 &&
  <Suspense fallback={ <div /> }>
                        <Pagination
                          isLoading={ loading }
                          totalPages= {totalPages}
                        />
                    </Suspense>          
}
</ContentWrapper>
         
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    updateBreadcrumbs: (breadcrumbs: any[]) => BreadcrumbsDispatcher.update(breadcrumbs, dispatch),
});

export default connect(null, mapDispatchToProps)(BlogListingPosts);
