import {
    FieldGroupContainer as SourceFieldGroupContainer,
} from 'SourceComponent/FieldGroup/FieldGroup.container';

/** @namespace TrouperPwa/Component/FieldGroup/Container */
export class FieldGroupContainer extends SourceFieldGroupContainer {
    // Add your custom logic here
}

export default FieldGroupContainer;
