import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';

import ContentWrapper from 'Component/ContentWrapper';
import BlogDetailPageQuery from 'Query/BlogDetailPage.query';
import Link from 'SourceComponent/Link';
import LoaderComponent from 'SourceComponent/Loader';
import { CmsBlock } from 'SourceRoute/Checkout/Checkout.component';
import { fetchQuery } from 'SourceUtil/Request/Query';
import BreadcrumbsDispatcher from 'Store/Breadcrumbs/Breadcrumbs.dispatcher';

import { BlogPostResponse } from './BlogDetail';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './BlogDetailPage.scss';
import '../BlogListingPosts/BlogListingPosts.scss';

interface BlogListingPostsProps {
    updateBreadcrumbs: (breadcrumbs: any[]) => void;
}

/** @namespace TrouperPwa/Component/BlogDetailPage/BlogDetailPage */
export const BlogDetailPage: React.FC<BlogListingPostsProps> = ({ updateBreadcrumbs }) => {
    const [blogDetail, setBlogDetail] = useState<BlogPostResponse['blogPost'] | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const currentUrl = `${window.location.origin}${location.pathname}${location.search}`;
    const postId = currentUrl.split('/').pop();
    const postUrlArray = currentUrl.split('/');
    const secondToLastValue = postUrlArray[postUrlArray.length - 1];

    useEffect(() => {
        const fetchBlogDetail = async () => {
            try {
                /* eslint-disable */
                const query = new BlogDetailPageQuery().blogPost(String(postId));
                // @ts-ignore
                const response: any = await fetchQuery<BlogPostResponse>(query);

                if (response?.blogPost) {
                    setBlogDetail(response.blogPost);
                    updateBreadcrumbs([
                        { name: `${secondToLastValue}`, url: `/blog/${secondToLastValue}` },
                        { name: 'Blogs', url: '/blog' },
                    ]);
                } else {
                    throw new Error('No blog post found.');
                }
            } catch (err) {
                setError('Failed to fetch blog details');
            } finally {
                setLoading(false);
            }
            /* eslint-disable */
        };

        fetchBlogDetail();
    }, [postId]);

    if (loading) {
        return <div><LoaderComponent isLoading={ loading } /></div>;
    }

    if (error) {
        return (
<div>
Error:
{ error }
</div>
        );
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        arrows: true,
        responsive: [
            { breakpoint: 1025, settings: { slidesToShow: 3, slidesToScroll: 1, centerPadding: '0' } },
            { breakpoint: 768, settings: { infinite: true, slidesToShow: 1, slidesToScroll: 1, centerMode: true, centerPadding: '60px', arrows: false, dots: true } },
        ],
    };

    return (
      <>
      <ContentWrapper>
    <div block="BlogDetailPage">

      <div block="BlogDetailPage" elem='DetailHeading'>{ blogDetail?.title }</div>

      <div block='BlogDetailPage' elem='DetailCategories'>
                            {blogDetail?.categories?.map((category:any) => (
               <Link
               to={{
                pathname: '/blog',
                search: `?category=${encodeURIComponent(category?.title)}&categoryId=${category?.category_id}`
            }} 
               >
               
               <button
                    key={category.category_id}
                    block='BlogDetailPage' elem='DetailCategory'
                >
                    {category.title}
                </button>
                </Link>
            ))}
       </div>

       <p block='BlogDetailPage' elem='blogDetailTime'>{blogDetail?.creation_time
                         ? format(new Date(blogDetail?.creation_time), "MMMM do yyyy")
                         : ""} - <span>{`${blogDetail?.reading_time} min read` || " "}</span></p> 
                        
       <div block='BlogDetailPage' elem='BlogDetailBanner'> <img src={ blogDetail?.featured_image } alt={ blogDetail?.featured_img_alt } /> </div>
       <div block='BlogDetailPage' elem='DetailSectionCol'  >
            <div block='BlogDetailPage' elem='DetailContent'  
            dangerouslySetInnerHTML={ {
                    __html: blogDetail?.filtered_content || '',
                } }
            >
            </div>
            <div block='BlogDetailPage' elem='BlogImageSection'>
            {(blogDetail?.categories?.length ?? 0) > 0 &&
             <>
            <h3 block='BlogDetailPage' elem='BlogCategoryTitle'> Blog Categories </h3>
                <div block='BlogDetailPage' elem='BlogDetailCategories'>
                                {blogDetail?.categories?.map((category:any) => (
                    <Link
                        key={category.category_id}
                        to={{
                            pathname: '/blog',
                            search: `?category=${encodeURIComponent(category.title)}&categoryId=${category.category_id}`
                        }}                    
                    >
                        <div block='BlogDetailPage' elem='BlogDetailCategory'  style={{display:'block'}}>

                        {category.title}
                        </div>
                    </Link>
                ))}
                </div>
                </>
                }
                <CmsBlock identifier={"blog-details-page-category"} />
            </div>
       </div>

    </div>
      </ContentWrapper>

      <div block='BlogDetailPage' elem="RecommendedSection">
        <ContentWrapper>
        <CmsBlock identifier="home-recommended-products" />
        </ContentWrapper>
     </div>

        {(blogDetail?.related_posts?.length ?? 0) > 0 && (

      <div block='BlogListingPosts' elem='RelatedPostMain'>

<h2>Related  blogs</h2>
<ContentWrapper>
{/* <div block='BlogListingPosts' elem='PostRowBlogDetail'> */}
<div block='BlogListingPosts' elem='PostRowBlogDetailCards'>
<Slider {...settings}>
             { blogDetail?.related_posts?.map((post: any) => (
                 <div block='BlogListingPosts' elem='PostCol' key={ post?.post_id }>
                   <div style={{position:"relative"}}>
                     <a href={`${post?.post_url}/${post.post_id}`} block='BlogListingPosts' elem='blogImg'><img src={ post?.featured_list_image }  alt="" /></a>
                     {post?.hotspot?.map((spot: any) => (
         <div
             key={spot.id}
             block="BlogListingPosts"
             elem="HotSpotMarker"
             style={{
                 position: "absolute",
                 top: spot.top,
                 left: spot.left,
                 transform: "translate(-50%, -50%)",
                 cursor: "pointer",
             }}
         >
             <a href={spot.url} block="BlogListingPosts"
             elem="HotSpotProduct">
               <span block="BlogListingPosts"
             elem="HotSpotProductName">{ spot?.name }</span>
             <span block="BlogListingPosts"
             elem={spot?.special_price ? "HotSpotProductSpecial" : "HotSpotProductPrice"} >{spot?.special_price ? spot?.special_price : spot?.price }</span>
             </a>
         </div>
     ))}
                     <div block='BlogListingPosts' elem='BlogCategories'>
                     {post?.categories?.map((category:any) => (
         <button
             key={category.category_id}
             block='BlogListingPosts' elem='BlogCategory'
             onClick={() => window.location.href = `${category.category_url}/${post?.post_id}`}
         >
             {category.title}
         </button>
     ))}
                     </div>
                   </div>
                   <div block='BlogListingPosts' elem='blogTitle'>
                     <h3 block='BlogListingPosts' elem='blogTitle'>{ post?.title }</h3>
                     <p block='BlogListingPosts' elem='blogTime'>{post?.creation_time
                         ? format(new Date(post?.creation_time), "MMMM do yyyy")
                         : ""}
                     </p>
                     <div block='BlogListingPosts' elem='blogContent' dangerouslySetInnerHTML={ {
               __html: post?.short_filtered_content,
           } }></div>
                 <div block='BlogListingPosts' elem='blogReadMore'>
                     <a href={`${post?.post_url}/${post.post_id}`}>Know more</a>
                 </div>
                 </div>
                 </div>
             )) }   
             </Slider> 
         </div>
        </ContentWrapper>
         </div>
        )}
      </>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    updateBreadcrumbs: (breadcrumbs: any[]) => BreadcrumbsDispatcher.update(breadcrumbs, dispatch),
});

export default connect(null, mapDispatchToProps)(BlogDetailPage);
