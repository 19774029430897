import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

import ComboProductsQuery from 'Query/ComboProducts.query';
/* eslint-disable */
// import LoaderComponent from 'SourceComponent/Loader';
import { fetchMutation } from 'SourceUtil/Request/Mutation';
import { fetchQuery } from 'SourceUtil/Request/Query';

import combo_plus_icon from '../../style/icons/combo_plus_icon.png';
import { ComboProduct } from './ComboProduct';

import './ComboProducts.scss';

interface ComboProductSkus {
    combo_prod_sku: string;
    qty: number;
}

/** @namespace TrouperPwa/Component/ComboProducts/ComboProducts */
export const ComboProducts: React.FC<{ dataSource: any }> = ({ dataSource }) => {
    const [comboProduct,
        setComboProduct,

    ] = useState<ComboProduct | null>(null);
    const [selectedComboProduct,
        setSelectedComboProduct,

    ] = useState<any>(null);
    const isMobile:boolean = useSelector((state:any) => state.ConfigReducer.device.isMobile);

    const [comboProductIds, setComboProductIds] = useState<number[]>([]);
    const [productSkus, setProductSkus] = useState<{ sku: string; quantity: number }[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const sku = dataSource?.sku;
    const mainProductId = comboProduct?.prod_id;
    // const cartId: any = localStorage.getItem('cart_id');
    // const cartData = JSON.parse(cartId);
    const cartIdString = localStorage.getItem('cart_id');
    const cartData = cartIdString ? JSON.parse(cartIdString) : null;
    const { token } = cartData?.data?.base || {};
    const comboPackId: any = comboProduct?.bundle_pack_id;

    const handleCheckboxChange = (comboId: number) => {
        setComboProductIds((prevSelectedIds) => (prevSelectedIds.includes(comboId)
            ? prevSelectedIds.filter((id) => id !== comboId)
            : [...prevSelectedIds, comboId]));
            // fetchComboProductSelectedQuery();
    };
    const handleAddToCart = useCallback(async () => {
        if (!selectedComboProduct?.[0]?.items_count) {
            return null;
        }

        try {
            setLoading(true);

            if (!token) {
                setError('Cart ID not found');

                return null;
            }

            const mutation = new ComboProductsQuery().addComboProductsToCart(token, productSkus, comboPackId);
            const response: any = await fetchMutation(mutation);

            if (response?.addComboProductsToCart?.success) {
                window.location.reload();
            } else {
                setError(' ');
            }
        } catch (err) {
            setError(' ');
        } finally {
            setLoading(false);
        }

        return undefined;
    }, [selectedComboProduct]);

    const fetchComboProductSelectedQuery = async () => {
        if (!mainProductId) {
            return;
        }

        try {
            setLoading(true);
            const query = new ComboProductsQuery().getComboProductSelectedQuery(mainProductId, comboProductIds);
            const response:any = await fetchQuery(query);

            if (response?.comboProductSelectedQuery) {
                const productsSku: any = response?.comboProductSelectedQuery?.[0]
                    ?.combo_products?.map(({ combo_prod_sku, qty }: ComboProductSkus) => ({
                        sku: combo_prod_sku,
                        quantity: qty,
                    })) || [];
                setProductSkus([
                    { sku, quantity: 1 },
                    ...productsSku,
                ]);

                setSelectedComboProduct(response?.comboProductSelectedQuery);
            }
        } catch (err) {
            setError(' ');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!sku) {
            return;
        }

        const fetchComboProduct = async () => {
            try {
                setLoading(true);
                setError('');

                const query = new ComboProductsQuery().getComboProductQuery(sku.toString());
                const response: any = await fetchQuery(query);

                if (response?.comboProductQuery?.[0]) {
                    setComboProduct(response?.comboProductQuery?.[0]);
                    const comboProductIds = response?.comboProductQuery?.[0]?.combo_products?.map(
                        (product: any) => product.combo_prod_id,
                    );

                    setComboProductIds(comboProductIds);
                } else {
                    throw new Error('No combo product found.');
                }
            } catch (err) {
                setError(' ');
            } finally {
                setLoading(false);
            }
        };

        fetchComboProduct();
    }, [sku]);

    useEffect(() => {
        fetchComboProductSelectedQuery();
    }, [comboProductIds]);

    // if (loading) {
    //     return <div><LoaderComponent isLoading={ loading } /></div>;
    // }

    if (error) {
        return <div>{ error }</div>;
    }

    if (!comboProduct) {
        return null;
    }

    const handleCheckboxChangeWrapper = (comboId: number) => () => {
        handleCheckboxChange(comboId);
    };

    const {
        parent_prod_name, bundle_pack_name, parent_prod_image, parent_prod_price, parent_prod_special_price,parent_prod_display_price, combo_products, bundle_pack_final_price,
        bundle_pack_final_savings, bundle_pack_total_final_price,
    } = comboProduct ?? {};

    const allProductsSelected = combo_products?.length === comboProductIds?.length;
    const subtotalPrice = allProductsSelected ? bundle_pack_final_price : selectedComboProduct?.[0]?.bundle_pack_final_price;
    const comboSavings = allProductsSelected ? bundle_pack_final_savings : selectedComboProduct?.[0]?.bundle_pack_final_savings;
    const totalFinalPrice = allProductsSelected ? bundle_pack_total_final_price : selectedComboProduct?.[0]?.bundle_pack_total_final_price;

    // const subtotalPrice = selectedComboProduct?.[0]?.selected_prod_subtotal || final_price;
    // const comboSavings = selectedComboProduct?.[0]?.selected_prod_final_discount || final_discount;
    return (
        <div block="ComboProducts">
            <div block="ComboProducts" elem="ComboWrap">
            <div block="ComboProducts" elem="PackHeading">

            <div block="ComboProducts" elem="PackName">{ bundle_pack_name }</div>
            { isMobile
            && (
<p block="ComboProducts" elem="SubTotal">
            AED
            { subtotalPrice }
            <span block="ComboProducts" elem="OriginalPrice">AED {totalFinalPrice}</span>
</p>
            ) }
            </div>

            <div block="ComboProducts" elem="Container">
            <div block="ComboProducts" elem="ProductSection">
                <div block="ComboProducts" elem="Product">
                  <div block="ComboProducts" elem="CurrentlyView">Currently Viewing</div>
                    <a href="/" block="ComboProducts" elem="Link">
                        <img src={ parent_prod_image } alt={ parent_prod_name } block="ComboProducts" elem="Image" />
                        {parent_prod_special_price ?
                        <div block="ComboProducts" elem= 'SpecialPrice' >
                            AED
{ ' ' }
{ parent_prod_display_price}
                        </div> : <div block="ComboProducts" elem='Price'>
                            AED
{ ' ' }
{ parent_prod_display_price }
                        </div>
                        }
                    </a>
                </div>

                <div block="ComboProducts" elem="Plus">
                    <img src={ combo_plus_icon } alt="combo product add" />
                </div>

{ combo_products?.map((product: any) => (
                    <React.Fragment key={ product?.combo_prod_id }>
                        <div block="ComboProducts" elem="ProductSub">
                            <input
                              type="checkbox"
                              block="ComboProducts"
                              elem="Checkbox"
                              checked={ comboProductIds.includes(product?.combo_prod_id) }
                              onChange={ handleCheckboxChangeWrapper(product?.combo_prod_id) }
                            />
                            <a href={ product?.combo_prod_url } block="ComboProducts" elem="Link">
                                <img
                                  src={ product?.combo_prod_image }
                                  alt={ product?.combo_prod_name }
                                  block="ComboProducts"
                                  elem="Image"
                                />
                                {product?.combo_special_price ? 
                                <div block="ComboProducts" elem='SpecialPrice'>
                                    AED
{ ' ' }
{ product?.combo_prod_display_price }
{/* <span block="ComboProducts" elem="ActualComboPrice">{ ' ' }AED {product?.actual_price}</span> */}
                                </div> : 
                                  <div block="ComboProducts" elem= 'Price'>
                                  AED
{ ' ' }
{  product?.combo_prod_display_price }
                              </div>
                                }
                            </a>
                        </div>
                    </React.Fragment>
)) }
            </div>
                  <div block="ComboProducts" elem="AddToCartSection">
                  { !isMobile
            && (
<p block="ComboProducts" elem="SubTotal">
            AED
            { subtotalPrice }
            <span block="ComboProducts" elem="OriginalPrice">AED {totalFinalPrice}</span>
</p>
            ) }
                <button
                  disabled={ selectedComboProduct?.[0]?.items_count < 2 }
                  block="ComboProducts"
                  elem="AddToCart"
                  onClick={ handleAddToCart }
                >
Add
{ ' ' }
{ selectedComboProduct?.[0]?.items_count >= 2 ? selectedComboProduct?.[0]?.items_count : '' }
{ ' ' }
Items To Cart
                </button>
                <p block="ComboProducts" elem="SavingPriceSection">
Combo up savings
{ ' ' }
{ ' ' }
<span block="ComboProducts" elem="SavingPrice">
AED
{ ' ' }
{ comboSavings }
</span>
                </p>
                  </div>
            </div>
            </div>
        </div>
    );
};


export default ComboProducts;
