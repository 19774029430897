import { ComponentType, Suspense } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { TransformWrapper } from 'react-zoom-pan-pinch';

import {
    ARROW_SAFE_AREA,
    CAROUSEL_ITEM_GAP, CAROUSEL_ITEM_WIDTH,
} from 'SourceComponent/CarouselScroll/CarouselScroll.config';
// import Image from 'Component/Image';
// import { ImageRatio } from 'Component/Image/Image.type';
import {
    CarouselScroll,
    ProductGalleryComponent as SourceProductGalleryComponent,
    VideoPopup,
} from 'SourceComponent/ProductGallery/ProductGallery.component';
import ProductGalleryBaseImage from 'SourceComponent/ProductGalleryBaseImage';
import ProductWishlistButton from 'SourceComponent/ProductWishlistButton';
import Slider from 'SourceComponent/Slider';
import { MediaGalleryEntry } from 'SourceQuery/ProductList.type';
import { ReactElement } from 'SourceType/Common.type';
import { ADD_TO_WISHLIST } from 'SourceUtil/Product';
import { IndexedProduct } from 'SourceUtil/Product/Product.type';
import { magentoProductTransform } from 'SourceUtil/Product/Transform';

// import { setLoadedFlag } from 'SourceUtil/Request/LowPriorityLoad';
import share_icon from '../../style/icons/share_icon.svg';
// import wishlist_yellow_icon from '../../style/icons/wishlist_yellow_icon.svg';
import { ProductGalleryComponentProps } from './ProductGallery.type';

import './ProductGallery.style';

export {
    CarouselScroll,
    VideoPopup,
};
/* eslint-disable */
/** @namespace TrouperPwa/Component/ProductGallery/Component */
export class ProductGalleryComponent extends SourceProductGalleryComponent {
    // @ts-ignore
    state = {
        showShareOptions: false,
        scrollEnabled: true,
        slidesCount: 5,
    };

    toggleShareOptions = () => {
        // @ts-ignore
        this.setState((prevState) => ({
            // @ts-ignore
            showShareOptions: !prevState.showShareOptions,
        }));
    };

    copyToClipboard = () => {
        const currentUrl = window.location.href;
        navigator.clipboard.writeText(currentUrl);
    };

    getShareUrl = (platform: string) => {
        const currentUrl = encodeURIComponent(window.location.href);

        switch (platform) {
        case 'facebook':
            return `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`;
        case 'whatsapp':
            return `https://api.whatsapp.com/send?text=${currentUrl}`;
        case 'twitter':
            return `https://twitter.com/intent/tweet?url=${currentUrl}&text=Check this out!`;
        default:
            return '#';
        }
    };

    renderMainTopCardStatus(): ReactElement {
        const {
            product: { is_salable },
            product: {
                attributes: { is_new: { attribute_value: is_new = '' } = {} } = {},
            },
            product: {
                attributes: { is_on_deal: { attribute_value: is_on_deal = '' } = {} } = {},
            },
            product: {
                attributes: { is_on_offer: { attribute_value: is_on_offer = '' } = {} } = {},
            },
        } = this.props as any;

        return (
            <>
            { !is_salable ? (
<div block="ProductCard" elem="CardTopStatusOutOfStock">
                OUT OF STOCK
</div>
            )
                : (is_on_deal === '1' && is_new === '1') ? (
<div block="ProductCard" elem="CardTopStatusDeal">
            DEAL
</div>
                )
                    : (is_on_deal === '1' && is_on_offer === '1') ? (
<div block="ProductCard" elem="CardTopStatusDeal">
        DEAL
</div>
                    )
                        : is_on_deal === '1' ? (
<div block="ProductCard" elem="CardTopStatusDeal">
    DEAL
</div>
                        )
                            : is_new === '1' ? (
<div block="ProductCard" elem="CardTopStatus">
                NEW
</div>
                            ) : '' }

            </>
        );
    }


        _calculateGallerySize(): void {
            const { isMobile } = this.props;
            const ref = this.galleryRef.current;
    
            if (!ref || isMobile) {
                return;
            }
            const { width } = ref.getBoundingClientRect();
    
            const slidesCount = Math.floor((width - ARROW_SAFE_AREA * 2) / (CAROUSEL_ITEM_WIDTH + CAROUSEL_ITEM_GAP));
            this.setState({ slidesCount });
        }

      renderWishlistButton(): ReactElement {
            const { product, isWishlistEnabled } = this.props as any;
    
            // if (!isWishlistEnabled) {
            //     return null;
            // }
    
            return (
                <ProductWishlistButton
                  magentoProduct={ magentoProductTransform(ADD_TO_WISHLIST, product as unknown as IndexedProduct) }
                  mix={ { block: 'ProductCard', elem: 'WishListButton' } }
                />
            );
        }

        renderImage(mediaData: MediaGalleryEntry, index: number): ReactElement {
            const {
                isZoomEnabled,
                handleZoomChange,
                disableZoom,
                isMobile,
                isImageZoomPopupActive,
                showLoader,
                productName,
            } = this.props;
            const { scrollEnabled } = this.state;
    
            // if (!isMobile) {
            //     const {
            //         base: { url: baseSrc } = {},
            //         large: { url: largeSrc } = {},
            //         label,
            //     } = mediaData;
    
            //     const style = isImageZoomPopupActive ? { height: 'auto' } : {};
            //     const src = isImageZoomPopupActive ? largeSrc || baseSrc : baseSrc;
    
            //     return (
            //         <Image
            //           key={ index }
            //           src={ src }
            //           ratio={ ImageRatio.IMG_CUSTOM }
            //           mix={ {
            //               block: 'ProductGallery',
            //               elem: 'SliderImage',
            //               mods: { isPlaceholder: !src },
            //           } }
            //           alt={ label || productName }
            //           isPlaceholder={ !src }
            //           style={ style }
            //           showIsLoading={ showLoader }
            //           // eslint-disable-next-line react/jsx-no-bind
            //           onImageLoad={ () => {
            //               setLoadedFlag();
            //               window.isPrefetchValueUsed = false;
            //           } }
            //         />
            //     );
            // }
    
            return (
                <TransformWrapper
                  key={ index }
                  onZoomChange={ handleZoomChange }
                  onWheel={ this.onWheel }
                  wheel={ { limitsOnWheel: true, disabled: !scrollEnabled } }
                  pan={ {
                      disabled: !isZoomEnabled,
                      velocity: false,
                  } }
                  options={ {
                      limitToBounds: true,
                      minScale: 1,
                  } }
                >
                    { /* @ts-ignore */ }
                    { (params: TransformRenderFnProps): ReactNode => {
                        const {
                            scale,
                            previousScale,
                            resetTransform,
                            setTransform,
                        } = params;
    
                        if (scale === 1 && previousScale !== 1) {
                            resetTransform();
                        }
    
                        return (
                            <ProductGalleryBaseImage
                              setTransform={ setTransform }
                              index={ index }
                              mediaData={ mediaData }
                              scale={ scale }
                              previousScale={ previousScale }
                              disableZoom={ disableZoom }
                              isZoomEnabled={ isZoomEnabled }
                            />
                        );
                    } }
                </TransformWrapper>
            );
        }


        renderAdditionalPictures(): ReactElement {
            const {
                gallery,
                isImageZoomPopupActive,
                activeImage,
                onActiveImageChange,
                isWithEmptySwitcher,
                isMobile
            } = this.props;
    
            const { slidesCount } = this.state;
    
            if (gallery.length === 1) {
                return <div block="ProductGallery" elem="Additional" mods={ { isWithEmptySwitcher } } />;
            }

            if (isMobile) {
                return (
                    <div block="ProductGallery" elem="Dots">
                        {gallery.map((_, index) => (
                            <span
                                key={index}
                                block="ProductGallery"
                                elem="Dot"
                                mods={{ isActive: activeImage === index }}
                                onClick={() => onActiveImageChange(index)}
                            />
                        ))}
                    </div>
                );
            }
    
            return (
                <div block="ProductGallery" elem="Additional" mods={ { isImageZoomPopupActive } }>
                    <Suspense fallback={ <div /> }>
                        <CarouselScroll
                          activeItemId={ activeImage }
                          onChange={ onActiveImageChange }
                          showedItemCount={ slidesCount }
                          isImageZoomPopupActive={ isImageZoomPopupActive }
                        >
                            { gallery.map(this.renderAdditionalPicture.bind(this)) }
                        </CarouselScroll>
                    </Suspense>
                </div>
            );
        }

            renderSlider(): ReactElement {
                const {
                    gallery,
                    activeImage,
                    isZoomEnabled,
                    onActiveImageChange,
                    isImageZoomPopupActive,
                    sliderRef,
                    isMobile,
                } = this.props;
        
                const mods = {
                    isImageZoomPopupActive,
                    isZoomInCursor: !isImageZoomPopupActive,
                };

                const isMoreThanOnePhoto = gallery.length > 1;
                return (
                    <div
                      ref={ this.imageRef }
                      block="ProductGallery"
                      elem="SliderWrapper"
                      data-is-pdp
                    >
                        <meta itemProp="image" content={ this.getImageUrl() } />
                        <Slider
                          sliderRef={ sliderRef }
                          mix={ { block: 'ProductGallery', elem: 'Slider', mods } }
                          showCounter={ isMobile }
                        //   showArrows={ !isMobile && isMoreThanOnePhoto }
                          activeImage={ activeImage }
                          onActiveImageChange={ onActiveImageChange }
                          isInteractionDisabled={ isZoomEnabled }
                          onClick={ this.handleSliderClick }
                          sliderHeight={ isImageZoomPopupActive ? '100%' : '' }
                          isHeightTransitionDisabledOnMount
                        >
                            { this.renderPlaceholderImage() }
                            { gallery.map(this.renderSlide) }
                        </Slider>
                    </div>
                );
            }

    render(): ReactElement {
        const { showShareOptions } = this.state;

        return (
                <div block="ProductGallery" ref={ this.galleryRef }>
                    { this.renderSlider() }
                    { this.renderAdditionalPictures() }
                    <Suspense fallback={ null }>
                        <VideoPopup />
                    </Suspense>
                    <div block="ProductGallery" elem="IconWrap" style={ { position: 'absolute', right: '-50px' } }>
                    <div block="ProductGallery" elem="WhishlistIcon">
                    {/* <img
                      src={ wishlist_yellow_icon }
                      alt="wishlist_yellow_icon"
                    /> */}
                    {this.renderWishlistButton()}
                    </div>
                    <div block="ProductGallery" elem="ShareIcon" onClick={ this.toggleShareOptions }>
                    <img
                      src={ share_icon }
                      alt="share_icon"
                    />
                    </div>
                 
                    </div>
                    { showShareOptions && (
                        <div block="ProductGallery" elem="ShareOptions">
                            <a href={ this.getShareUrl('facebook') } target="_blank" rel="noopener noreferrer">
                                <img src="https://cdn-icons-png.flaticon.com/512/733/733547.png" alt="Facebook" />
                            </a>
                            <a href={ this.getShareUrl('whatsapp') } target="_blank" rel="noopener noreferrer">
                                <img src="https://cdn-icons-png.flaticon.com/512/733/733585.png" alt="WhatsApp" />
                            </a>
                            <a href={ this.getShareUrl('twitter') } target="_blank" rel="noopener noreferrer">
    <img src="https://cdn-icons-png.flaticon.com/512/733/733579.png" alt="Twitter" width="24" height="24" />
                            </a>
                            <button onClick={ this.copyToClipboard }>📋 Copy Link</button>
                        </div>
                    ) }
                    <div style={ { position: 'absolute', left: '-50px' } }>
                        { this.renderMainTopCardStatus() }
                    </div>

                </div>
        );
    }
}

export default withRouter(
    ProductGalleryComponent as unknown as ComponentType<
    RouteComponentProps & ProductGalleryComponentProps
    >,
);
