import { Suspense } from 'react';

import {
    AddToCart,
    FieldContainer,
    GroupedProductList,
    ProductBundleOptions,
    ProductCompareButton,
    ProductComponent as SourceProductComponent,
    ProductConfigurableAttributes,
    ProductCustomizableOptions,
    ProductDownloadableLinks,
    ProductDownloadableSamples,
    ProductReviewRating,
    ProductWishlistButton,
} from 'SourceComponent/Product/Product.component';
import { ReactElement } from 'SourceType/Common.type';

export {
    ProductReviewRating,
    ProductConfigurableAttributes,
    AddToCart,
    FieldContainer,
    ProductCustomizableOptions,
    ProductBundleOptions,
    GroupedProductList,
    ProductCompareButton,
    ProductDownloadableLinks,
    ProductDownloadableSamples,
    ProductWishlistButton,
};

/** @namespace TrouperPwa/Component/Product/Component */
export class ProductComponent extends SourceProductComponent {
    renderCustomizableOptions(): ReactElement {
        const {
            product: {
                options,
            },
            product,
            updateSelectedValues,
        } = this.props;

        return (
                <Suspense fallback={ null }>
                    <ProductCustomizableOptions
                      options={ options }
                      product={ product }
                      updateSelectedValues={ updateSelectedValues }
                    />
                </Suspense>
        );
    }
}

export default ProductComponent;
