import { ComponentType } from 'react';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductContainer as SourceProductContainer,
} from 'SourceComponent/Product/Product.container';
import { ProductContainerProps } from 'SourceComponent/Product/Product.type';
import { ADD_TO_CART } from 'SourceUtil/Product';
import { magentoProductTransform, transformParameters } from 'SourceUtil/Product/Transform';
/* eslint-disable */
export {
    mapDispatchToProps,
    mapStateToProps,
};
import {
    ProductTransformData,
} from 'Util/Product/Product.type';
/** @namespace TrouperPwa/Component/Product/Container */
export class ProductContainer extends SourceProductContainer {

    validator: HTMLElement | null = null;

    __construct(props: ProductContainerProps): void {
        const { parameters } = props;

        super.__construct?.(props);

        // TODO: There is a strange error when type isn't compatible with the same type.
        // Probably this is related to the fact that the class is generic.
        // Need to investigate this later.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.state = {
            // Used for customizable & bundle options
            enteredOptions: this.setDefaultProductOptions('defaultEnteredOptions', 'enteredOptions'),
            selectedOptions: this.setDefaultProductOptions('defaultSelectedOptions', 'selectedOptions'),
            addToCartTriggeredWithError: false,
            // Used for downloadable
            downloadableLinks: [],

            quantity: 1,

            // Used to add to the base price a selected option prices
            adjustedPrice: {},

            // Used for configurable product - it can be ether parent or variant
            selectedProduct: null,
            parameters,
            unselectedOptions: [],
            currentProductSKU: '',
            activeProduct: null,
            // @ts-ignore
            checkboxChecked: false,
        };
    }

    componentDidMount() {
        document.addEventListener(
            'CheckboxStateChanged',
            this.handleCheckboxChange as EventListener,
        );
    }

    componentWillUnmount() {
        document.removeEventListener(
            'CheckboxStateChanged',
            this.handleCheckboxChange as EventListener,
        );
    }

    handleCheckboxChange = (event: Event) => {
        const customEvent = event as CustomEvent<{ attributeCode: string; isChecked: boolean }>;

        if (customEvent?.detail?.isChecked !== undefined) {
            setTimeout(() => {
                // @ts-ignore
                this.setState({ checkboxChecked: customEvent.detail.isChecked });
            }, 100);
        }
    };

    /**
     * Event that validates and invokes product adding into cart
     * @returns {*}
     */
    async addToCart(): Promise<void> {
        this.updateSelectedValues();
        const { showError } = this.props;

        if (this.hasError()) {
            return;
        }

        const { addProductToCart, cartId } = this.props;
        const products = this.getMagentoProduct();

        await addProductToCart({ products, cartId })
            .catch(
                /** @namespace Component/Product/Container/ProductContainer/addToCart/addProductToCart/catch */
                (error: string) => {
                    if (error) {
                        showError(error);
                    }
                },
            );
    }


     /**
     * Returns magento graphql compatible product data
     * @returns {*[]}
     */
     getMagentoProduct(): ProductTransformData[] {
        const {
            quantity,
            enteredOptions,
            selectedOptions,
            downloadableLinks,
            parameters,
            // @ts-ignore
            checkboxChecked,
        } = this.state;

        const { product, product: { attributes = {} } } = this.props as any;

        const configurableOptions = transformParameters(parameters, attributes);
     const transformedProducts = magentoProductTransform(
        ADD_TO_CART,
        product,
        quantity,
        enteredOptions,
        [...selectedOptions, ...downloadableLinks, ...configurableOptions],
    );

    return transformedProducts.map((productData) => ({
        ...productData,
        is_custom_attribute_selected_flag: checkboxChecked,
    }));
    }
}





export default connect(mapStateToProps, mapDispatchToProps)(
    ProductContainer as unknown as ComponentType<ProductContainerProps>,
);
