import {
    ProductWishlistButtonComponent as SourceProductWishlistButtonComponent,
} from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.component';
import { ReactElement } from 'SourceType/Common.type';

import wishlist_gray_icon from '../../style/icons/whishlist_gray_icon.svg';
import wishlist_yellow_icon from '../../style/icons/wishlist_yellow_icon.svg';

import './ProductWishlistButton.style';

/** @namespace TrouperPwa/Component/ProductWishlistButton/Component */
export class ProductWishlistButtonComponent extends SourceProductWishlistButtonComponent {
    renderButton(): ReactElement {
        const { isInWishlist, isDisabled } = this.props;

        return (
                <button
                  block="ProductWishlistButton"
                  elem="Button"
                  mods={ { isInWishlist, isDisabled } }
                //   mix={ { block: 'Button', mix } }
                  title={ this.getTitle() }
                  onClick={ this.onClick }
                >
                    { /* <HeartIcon isActive={ isInWishlist } /> */ }
                    <img
                      src={ isInWishlist ? wishlist_yellow_icon : wishlist_gray_icon }
                      alt="wishlist_yellow_icon"
                    />
                </button>
        );
    }
}

export default ProductWishlistButtonComponent;
