import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductWishlistButtonContainer as SourceProductWishlistButtonContainer,
} from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.container';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace TrouperPwa/Component/ProductWishlistButton/Container */
export class ProductWishlistButtonContainer extends SourceProductWishlistButtonContainer {
    // TODO implement logic
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductWishlistButtonContainer);
