/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import ContentWrapper from 'Component/ContentWrapper';
import {
    NoMatchComponent as SourceNoMatchComponent,
} from 'SourceRoute/NoMatch/NoMatch.component';
import { ReactElement } from 'Type/Common.type';
import { scrollToTop } from 'Util/Browser';

import CmsBlock from '../../component/CmsBlock/CmsBlock.container';

import './NoMatch.style';

/** @namespace TrouperPwa/Route/NoMatch/Component */
export class NoMatchComponent extends SourceNoMatchComponent {
    /* eslint-disable */
    componentDidMount(): void {
        this.updateBreadcrumbs();
        scrollToTop();
    }
    /* eslint-disable */

    updateBreadcrumbs(): void {
        const { updateBreadcrumbs } = this.props;
        const breadcrumbs = [
            {
                url: '',
                name: __('Not Found'),
            },
        ];

        updateBreadcrumbs(breadcrumbs);
    }

    render(): ReactElement {
        return (
            <main block="NoMatch" aria-label={ __('Page not found') }>
                <ContentWrapper
                  mix={ { block: 'NoMatch' } }
                  wrapperMix={ { block: 'NoMatch', elem: 'Wrapper' } }
                  label={ __('Page Not Found Content') }
                >

<div>
                    <CmsBlock identifier="page_not_found" />
</div>

                </ContentWrapper>
            </main>
        );
    }
}

export default NoMatchComponent;
