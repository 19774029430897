import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { Page } from 'Component/Header/Header.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductPageContainer as SourceProductPageContainer,
} from 'SourceRoute/ProductPage/ProductPage.container';
import { ReactElement } from 'SourceType/Common.type';
import history from 'Util/History';
import { RootState } from 'Util/Store/Store.type';

import ProductPage from './ProductPage.component';
import {
    ProductPageComponentProps,
    ProductPageContainerComponentPropKeys,
} from './ProductPage.type';
/** @namespace TrouperPwa/Route/ProductPage/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    /* eslint-disable */
    // @ts-ignore
    tabListHeadings: state?.ConfigReducer?.attribute_tab_list,
    isEnabled: state.ConfigReducer.reviews_are_enabled,
    // TODO extend mapStateToProps
});

/** @namespace TrouperPwa/Route/ProductPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});
/** @namespace TrouperPwa/Route/ProductPage/Container */
export class ProductPageContainer extends SourceProductPageContainer{
    containerProps(): Pick<ProductPageComponentProps, ProductPageContainerComponentPropKeys> {
        const {
            isMobile, areReviewsEnabled, hideReviews, pathname, tabListHeadings, isEnabled, isQuickViewVisible
        } = this.props as any;
        const { parameters } = this.state;
        const { location } = history;

        return {
            areDetailsLoaded: this.getAreDetailsLoaded(),
            isAttributesTabEmpty: this.isProductAttributesTabEmpty(),
            isInformationTabEmpty: this.isProductInformationTabEmpty(),
            activeProduct: this.getActiveProductDataSource(),
            dataSource: this.getDataSource(),
            useEmptyGallerySwitcher: this.getUseEmptyGallerySwitcher(),
            isVariant: this.getIsVariant(),
            isMobile,
            parameters,
            location,
            areReviewsEnabled,
            hideReviews,
            pathname,
            tabListHeadings,
            isEnabled,
            isQuickViewVisible,
        };
    }


        updateHeaderState(): void {
            const { name = '' } = this.getDataSource();
            const { changeHeaderState, isQuickViewVisible } = this.props as any;
    
                
        if (isQuickViewVisible) {
            return;
        } 
            changeHeaderState({
                name: Page.PDP,
                title: name,
                onBackClick: () => history.goBack(),
            });
        }


    updateBreadcrumbs(): void {
        const { updateBreadcrumbs, goToPreviousNavigationState, isQuickViewVisible } = this.props as any;
        const { location: { state: { prevCategoryId = 0 } = {} } } = history;
    
        if (isQuickViewVisible) {
            return;
        }
        goToPreviousNavigationState();
        const {
            name = '',
            url= '',
            categories = [],
        } = this.getDataSource();
    
        updateBreadcrumbs(
            {
                name,
                url: { pathname: url },
                categories,
            },
            prevCategoryId
        );
    }

    render(): ReactElement {
        return (
                <ProductPage
                  { ...this.containerFunctions }
                  { ...this.containerProps() }
                />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPageContainer);
